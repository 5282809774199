if (window !== top) {
    top.location.href = location.href;
}

$(function () {
    window.bLazy = new Blazy({
        src: 'data-blazy', // Default is data-src, but that has angular issues
        selector: '.lazyload'
    });

    setTimeout(function () {
        $(".content .info").hide('blind', {}, 250);
    }, 5000);
});

/*! ResponsiveMenu | multoo.nl */
;(function ($, window) {
    "use strict";

    $.fn.responsiveMenu = function (options) {

        var defaults = {
            title: 'Menu',  // Menu titel
            append: false,   // Template achter het menu plaatsen, anders er voor (prepend)
            debug: false,

            tpl: {
                burger: '<h1 class="menu-toggle"></h1>'
            }
        };

        var settings = $.extend({}, defaults, options);
        var template = $(settings.tpl.burger).html(settings.title);

        var menu = {
            el: null,           // Element
            docWidth: 0,        // Current document width
            hoverEvent: false,  // If hover events are bound to normal menu
            isOpen: false,      // If the menu has an open state
            mobileView: false,  // If shown menu is mobile
            normalView: false,  // If shown menu is normal

            init: function (el) {
                this.el = el;
                $(this.el).find('ul:first-child').addClass('responsiveMenu');

                this.bindClick();
                this.onResize();
            },

            onResize: function () {
                this.docWidth = $(this.el).width();

                if (this.docWidth <= 767) {
                    // Responsive mobile
                    if (this.normalView) {
                        this.closeMenu();
                        this.unbindHover();
                    }

                    this.mobileView = true;
                    this.normalView = false;
                } else {
                    // Not responsive
                    this.showNormalMenu();
                    this.bindHover();
                    this.mobileView = false;
                    this.normalView = true;
                }

                if (settings.debug) {
                    this.debug();
                }
            },

            closeMenu: function () {
                $(this.el).find("ul:first-child").hide();
                this.isOpen = false;
            },

            openMenu: function () {
                $(this.el).find("ul:first-child").show();
                this.isOpen = true;
            },

            showNormalMenu: function () {
                if (this.isOpen && this.mobileView) {
                    this.closeMenu();
                }

                $(this.el).find('ul:first-child').show();
            },

            bindClick: function () {
                var that = this;
                $(this.el).find(template).bind('click', function () {
                    if (that.isOpen) {
                        that.closeMenu();
                    } else {
                        that.openMenu();
                    }
                });

                if (settings.debug) {
                    console.log('Bind click event');
                }
            },

            bindHover: function () {
                if (!this.hoverEvent) {
                    $(this.el).find("ul > li").hover(function () {
                        $(this).find('ul:first-child').show(400);
                    }, function () {
                        $(this).find('ul:first-child').hide();
                    });

                    this.hoverEvent = true;

                    if (settings.debug) {
                        console.log('Bind hover event');
                    }
                }
            },

            unbindHover: function () {
                if (this.hoverEvent) {
                    $(this.el).find("li").unbind('mouseenter mouseleave');

                    this.hoverEvent = false;

                    if (settings.debug) {
                        console.log('Unbind hover event');
                    }
                }
            },

            debug: function () {
                console.log('docWidth: ' + this.docWidth + ' | isOpen: ' + this.isOpen + ' | mobileView: ' + this.mobileView + ' | normalView: ' + this.normalView + ' | HoverEvent: ' + this.hoverEvent);
            }
        }

        if (settings.append !== true) {
            this.prepend(function () {
                return template;
            });
        } else {
            this.append(function () {
                return template;
            });
        }

        menu.init(this);

        $(window).resize(function () {
            menu.onResize(this);
        });

        // return the jQuery object for chaining
        return this;
    };
}(jQuery, window));